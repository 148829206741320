.table {

  &:last-child {
    margin-bottom: 0;
  }

  tbody td, th {
    border: none;
    padding: 10px;

    @include themify($themes) {
      color: themed('colorTextAdditional');
    }
  }

  thead th {

    @include themify($themes) {
      color: themed('colorText');
      border-bottom-color: themed('colorBorder');
    }
  }

  .badge {
    color: #ffffff;
    padding: 6px 20px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 400;

    &.badge-success {
      background-color: $color-accent;
    }

    &.badge-warning {
      background-color: $color-yellow;
    }

    &.badge-primary {
      background-color: $color-template;
    }

    &.badge-danger {
      background-color: $color-red;
    }

    &.badge-disabled {
      background-color: #dddddd;
    }
  }

  &.table--bordered {

    tr {

      @include themify($themes) {
        border-bottom: 1px solid themed('colorBorder');
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.table--head-accent {

    thead {

      @include themify($themes) {
        color: themed('colorTextAdditional');
        background-color: themed('colorHover');
      }

      th {
        border-bottom: none;
      }
    }
  }

  &.table-striped {
    
    tbody tr:nth-of-type(odd) {

      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
  }

  &.table-hover {

    tbody tr {
      transition: all 0.3s;
      cursor: pointer;

      &:hover {

        @include themify($themes) {
          background-color: themed('colorBackgroundBody');
        }
      }
    }
  }
}
@media screen and (min-width: 575.5px) {
  .listData_mobile{
      display: none;
  }

}

@media screen and (max-width: 575.5px) {
  .table_data_Desktop{
      display: none;
  }
  
}
.bulkUploadModalHeight{
  // top: 200Px;
  width:450Px;
}
.rowdata_end{
  // direction: rtl;
  text-align: right;
  flex-direction: row-reverse;
  text-align: right;
}
.subject_text{
  font-weight: 500;
  font-size: 16px;
}
