.form-wrapper{
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: center;
}
.p-password-input{
    width: 100% !important;
}
.p-multiselect-label-container{
    height: unset;
}